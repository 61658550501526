<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="8" lg="8" cols="12">
          <b-card-actions
              class="list-card"
              ref="first-card"
              @refresh="refreshStop('first-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Komisyon Üyeleri</h4>
                </b-col>
              </div>
            </b-row>
            <table class="table table-hover table-sm mt-5">
              <thead>
              <tr>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Ad Soyad
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Unvan
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                />
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item ,i) in uyeler" :key="i">
                <td style="width: 50%">
                  {{ item.adSoyad }}
                </td>
                <td style="width: 20%">
                  {{ item.unvan }}
                </td>
                <td style="width: 10%">
                  <b-row class="d-flex justify-content-end">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        class="btn-icon rounded-circle mr-50"
                        v-b-tooltip="'Düzenle'"
                        size="sm"
                        @click="updateStepOne(item)"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgWarning+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen"/>
                    </b-button>
                    <b-button
                        variant="gradient-danger"
                        class="btn-icon rounded-circle mr-50"
                        size="sm"
                        v-b-tooltip.right="'Sil'"
                        @click="remove(item.uyeID)"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </b-row>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col xl="4" lg="4" cols="12">
          <b-card-actions
              class="list-card"
              ref="second-card"
              @refresh="refreshStop('second-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row"
                   :style="{backgroundColor: !edit ? $store.getters.COLORS_GET.cardTitle : 'orange'}"
              >
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">{{
                      !edit ? 'Üye Kayıt' : oldName + ' Adlı Komisyon Üyesi Düzenleniyor'
                    }}</h4>
                </b-col>
              </div>
            </b-row>
            <b-form @submit.prevent class="mt-5">
              <b-row>
                <b-col cols="12" class="mt-sm-2">
                  <b-form-group
                      label="Adı Soyadı"
                      label-for="vi-first-name"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-user"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="vi-first-name"
                          autocomplete="off"
                          v-model="name"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Ünvan"
                      label-for="unvan"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-feather"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="vi-first-name"
                          autocomplete="off"
                          v-model="unvan"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col offset-md="4" class="text-right">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-50"
                      @click="!edit  ? save() : updateStepSecond()"
                      :disabled="!edit && (!name || !unvan)"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                  >
                    <font-awesome-icon :icon="'fa-solid' + !edit ? 'fa-floppy-disk':'fa-user-pen'" class="mr-50"/>
                    {{ !edit ? 'Kaydet' : 'Güncelle' }}
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="danger"
                      @click="reset()"
                      v-if="edit"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                  >
                    Vazgeç
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BForm,
  BTab,
  BTabs,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BFormTextarea,
  BCard,
  BCardText,
  BContainer,
  BFormSelect,
  BFormInvalidFeedback,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem, BInputGroupPrepend, BPagination,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  components: {
    BForm,
    vSelect,
    BFormInvalidFeedback,
    BPagination,
    BInputGroupPrepend,
    BTab,
    BTabs,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormTextarea,
    flatPickr,
    BCard,
    BCardText,
    BContainer,
    BFormSelect,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
  },
  data() {
    return {
      uyeID: null,
      unvan: null,
      name: null,
      edit: false,
      oldName: null,
    }
  },
  computed: {
    uyeler() {
      return store.getters.KOMISYON_UYELER_GET
    },
  },
  methods: {
    updateStepSecond() {
      store.dispatch('komisyonUyeDuzenle', {
        uyeID: this.uyeID,
        adSoyad: this.name,
        unvan: this.unvan,
      })
          .then((res, position = 'bottom-right') => {
            this.getKomisyonUyeleri()
            this.reset()
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    updateStepOne(item) {
      this.edit = true
      this.uyeID = item.uyeID
      this.name = item.adSoyad
      this.oldName = item.adSoyad
      this.unvan = item.unvan
    },
    remove(uyeID) {
      store.dispatch('komisyonUyeSil', uyeID)
          .then((res, position = 'bottom-right') => {
            this.getKomisyonUyeleri()
            this.reset()
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    save() {
      store.dispatch('komisyonUyeKaydet', {
        adSoyad: this.name,
        unvan: this.unvan,
      })
          .then((res, position = 'bottom-right') => {
            this.getKomisyonUyeleri()
            this.reset()
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    getKomisyonUyeleri() {
      store.dispatch('komisyonUyeleri')
    },
    reset() {
      this.edit = false
      this.uyeID = null
      this.name = null
      this.unvan = null
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
  beforeDestroy() {
    store.commit('KOMISYON_UYELER_ACTION', 'remove')
  },
  mounted() {
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created() {
    this.getKomisyonUyeleri()
  },
  destroyed() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
